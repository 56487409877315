<template>
    <div class="live-room" element-loading-text="连接中" v-if="roomId">
        <div class="container">
            <div class="data-board" v-if="roomId && liveState == 1" @click="toDataBoard">
                <div class="line">查看</div>
                <div class="line">后台</div>
                <div class="line">数据</div>
            </div>
            <live-script
                class="radius"
                :scriptData="scriptCarousel"
                :liveState="liveState"
                :currentScript="currentScript"
            ></live-script>
            <live-main
                class="radius"
                ref="liveMain"
                :room-id="roomId"
                @start-live.once="sendStarLive"
                @setProp="setProp"
                @changeData="setChangeData"
                @bulletChatChange="setBulletChat"
            ></live-main>
            <live-other-info
                :popularityData="popularityData"
                :bulletChatList="bulletChatList"
                :liveState="liveState"
                :liveTime="liveTime"
            ></live-other-info>
        </div>
        <my-dialog
            title="提示"
            :is-show.sync="debugDialog"
            :button-config="debugDialogButton"
            :confirm-handler="startLiveInit"
            :cancel-handler="toDebugger"
            :top="50"
        >
            为了避免直播中的失误，您可以选择先进行直播调试后再开始直播， 或者您也可以选择直接开始。
        </my-dialog>
    </div>
</template>

<script>
import LiveScript from "components/student/liveRoom/LiveScript";
import LiveMain from "components/student/liveRoom/LiveMain";
import LiveOtherInfo from "components/student/liveRoom/LiveOtherInfo";
import MyDialog from "components/common/MyDialog";

import { getSteamLocation, liveRoom_list } from "@/utils/apis.js";

export default {
    name: "LiveRoom",
    data() {
        return {
            liveTime: null,
            currentScript: {},
            liveState: 1,
            roomId: void 0,
            connectLoading: false,
            //左侧脚本控制相关
            scriptCarousel: [],
            //右侧直播间信息相关
            popularityData: {
                onlineTotalNum: 0,
                inOnlineNum: 0,
                outOnlineNum: 0,
                dealNum: 0,
                like: 0,
            },
            bulletChatList: [],
            //dialog
            debugDialogButton: [{ hidden: true }, { text: "直接开播" }],
            debugDialog: false,
        };
    },
    provide: function () {
        const { toggleDebugDialog } = this;
        return {
            toggleDebugDialog,
        };
    },
    computed: {},
    components: {
        LiveScript,
        LiveMain,
        LiveOtherInfo,
        MyDialog,
    },
    methods: {
        async init() {
            this.useRoomId();
        },
        async useRoomId() {
            const res = await liveRoom_list();
            if (res.code == 200 && res.data.length > 0) {
                this.roomId = res.data[0].id;
            }
        },
        setProp({ key, value }) {
            this[key] = value;
        },
        setViewInfo(res) {},
        toggleDebugDialog(state) {
            this.debugDialog = state;
        },
        startLiveInit() {
            if (!this.roomId) {
                this.$message.error("请确保已创建直播间，如已创建请重新刷新网页重试");
                return void 0;
            }
            this.debugDialog = false;
            this.$refs.liveMain.startButtonHandler();
        },

        setChangeData(res) {
            const { like } = res.data;
            const { leave_online_number, online_total_number, in_online_number, deal_number } = res.data;
            const getValue = (value) => (value ? value : 0);
            this.popularityData.outOnlineNum = getValue(leave_online_number);
            this.popularityData.onlineTotalNum = getValue(online_total_number);
            this.popularityData.inOnlineNum = getValue(in_online_number);
            this.popularityData.dealNum = getValue(deal_number);
            this.popularityData.like = getValue(like);
            // this.$set(this.userInfo, "fans", getValue(fans));
            // this.$set(this.userInfo, "like", getValue(like));
            // this.ws.send(JSON.stringify(this.wsActions.getScriptInfo));
        },
        setBulletChat(arr) {
            this.bulletChatList = arr;
        },
        toDataBoard() {
            let url = this.$router.resolve({
                name: "studentLiveDatas",
                path: "/livedata/livedatas",
                query: {
                    user_id: localStorage.getItem("studentId"),
                    train_id: localStorage.getItem("train_id"),
                    room_id: this.roomId,
                },
            });
            window.open(url.href, "_blank");
        },
        toDebugger() {
            this.$router.push("/liveDebug");
        },
    },
    mounted() {
        this.scriptId = localStorage.getItem("scriptId");
        this.init();
    },
    beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.live-room {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    user-select: none;

    .container {
        position: relative;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        padding: 20px;
        background-color: #070932;
        .data-board {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 22px;
            width: 104px;
            height: 93px;
            color: #fff;
            font-size: 14.5px;
            font-weight: 700;
            background: url("../../assets/image/student/live/flag.png") no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
        }
    }
}

.radius {
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    /* background-color: #0c0e3f; */
}
::v-deep .popularity,
::v-deep .bullet-chat {
    @extend .radius;
}
</style>
