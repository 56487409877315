<template>
    <div class="not-start-script">
        <div class="line"></div>
        <div class="total-list">
            <div class="total-item" v-for="(item, index) in packScriptData" :key="item.id">
                <!-- <div class="title-box">
                    <div class="sequential-num">{{ index + 1 }}</div>
                    <div class="title">{{ item.title }}</div>
                </div> -->
                <div class="content">
                    <div class="script-snippet-box">
                        <div class="script-snippet-item">
                            <div class="time">{{ item.endTime | getViewTime(index) }}</div>
                            <div class="text">{{ item.script_content }}</div>
                        </div>
                        <!-- <template v-if="item.type == 1">
                            <div class="script-snippet-item">
                                <div class="time">{{ (item.minute * 60 + item.seconds * 1) | getViewTime(index) }}</div>
                                <div class="text">{{ item.script_content }}</div>
                            </div>
                        </template>
                        <template v-if="item.type == 2">
                            <div class="script-snippet-item">
                                <div class="time">{{ item.end_time | getViewTime(index) }}</div>
                                <div class="text">
                                    <div v-for="subItem in item.children" :key="subItem.id">
                                        {{ subItem.script_content }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="item.type == 3">
                            <div class="script-snippet-item" v-for="(opeItem, opeIndex) in item.operation" :key="opeItem.id">
                                <div class="time">{{ item.operation | getBagAndGoodsTime(item, opeIndex) | getViewTime(index) }}</div>
                                <div class="text">
                                    <div>{{ opeItem.script_content }}</div>
                                    <template v-for="subItem in opeItem.children">
                                        <div :key="subItem.id">{{ subItem.script_content }}</div>
                                    </template>
                                </div>
                            </div>
                        </template> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotStartScript",
    data() {
        return {};
    },
    props: {
        scriptData: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        packScriptData() {
            const list = [];
            const marks = [1, 1, 1];
            const titles = ["脚本", "福袋", "商品"];
            this.scriptData.forEach((item) => {
                const index = item.con_type - 1;
                const obj = {
                    ...item,
                    type: item.con_type,
                    title: `${titles[index]}${marks[index]}`,
                };
                list.push(obj);
                marks[index] = marks[index] + 1;
            });
            return list;
        },
    },
    components: {},
    filters: {
        getViewTime(sumSecond, index) {
            if (index <= 0) return "0″";
            const minute = parseInt(sumSecond / 60);
            const second = sumSecond % 60;
            return minute > 0 ? `${minute}′${second}″` : `${second}″`;
        },
        // getBagAndGoodsTime(children, item, opeIndex) {
        //     const second = children.reduce((sum, numItem, numIndex) => sum + (numIndex <= opeIndex ? numItem.answer_time * 1 : 0), 0);
        //     return item.end_time * 1 + second;
        // },
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.not-start-script {
    position: relative;
    .line {
        position: absolute;
        top: 0;
        left: 15%;
        bottom: 20px;
        width: 1px;
        background-color: #444797;
    }
    .total-list {
        .total-item {
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;
            .title-box {
                position: relative;
                display: flex;
                align-items: center;
                align-self: flex-end;
                width: 85%;
                margin-bottom: 15px;
                font-size: 18px;
                transform: translateX(-13px);
                .sequential-num {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 50%;
                    background-color: #bfbfbf;
                }
            }
            .content {
                display: flex;
                width: 100%;
                .script-snippet-box {
                    width: 100%;
                    box-sizing: border-box;
                    opacity: 0.6;
                    line-height: 30px;
                    overflow: hidden;
                    .script-snippet-item {
                        display: flex;
                        width: 100%;
                        overflow: hidden;
                        .time {
                            box-sizing: border-box;
                            width: 15%;
                            padding-right: 15px;
                            text-align: end;
                            overflow: hidden;
                        }
                        .text {
                            box-sizing: border-box;
                            width: 85%;
                            padding: 0 30px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
</style>
